import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
  --red:  ${(props) => props.theme.colors.red};
  --blue:  ${(props) => props.theme.colors.blue};
  --grey:  ${(props) => props.theme.colors.grey};
  --darkGrey:  ${(props) => props.theme.colors.darkGrey};
  --black:  ${(props) => props.theme.colors.black};
  --white:  ${(props) => props.theme.colors.white};
  --beige: ${(props) => props.theme.colors.beige};
  --darkBlue: ${(props) => props.theme.colors.darkBlue};
  --yellow:  ${(props) => props.theme.colors.yellow};
}

html,
body, #__next {
    color: var(--darkGrey);
    font-weight: 700;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  background: var(--grey);
  height: 100%;
  margin: 0;
  min-height: 100vh;
}


h1 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3rem;
  letter-spacing: 1.6px;
  color: #fff;
  margin-bottom: 1rem;
  font-family: ${(props) => props.theme.fonts.serifDisplay};
}

h2,
h3,
h4 {
  font-family: ${(props) => props.theme.fonts.serif};
  font-weight: 400;
  color: #fff;
  font-size: 1.5em;
  line-height: 1.25em;
}

h3 {
  font-size: 1.3rem;
  font-weight: 400;
}

h4 {
  color: #000;
  font-size: 1.2em;
}

h5 {
  font-size: 0.95rem;
  line-height: 1.3rem;
  font-family: ${(props) => props.theme.fonts.sansSerif};
}

p,
li {
  font-family: ${(props) => props.theme.fonts.serif};
  font-size: 1em;
  line-height: 1.65em;
  font-weight: 100;
}

a {
  font-family: ${(props) => props.theme.fonts.serif};
  font-size: 1em;
  font-weight: 100;
}

strong {
  font-family: ${(props) => props.theme.fonts.serif};
}

label {
  font-family: ${(props) => props.theme.fonts.sansSerif};
  font-weight: 500;
}

input {
  font-family: ${(props) => props.theme.fonts.sansSerif};
  font-weight: 400;
}

p {
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
  color: var(--red);
  font-family: ${(props) => props.theme.fonts.sansSerif};

  &:hover {
    text-decoration: underline;
  }
}

li {
  font-size: 1.1em;
  line-height: 1.8em;
}


p,
li {
  font-size: 0.9rem;
}

ol {
  list-style-position: inside;
}

small {
  font-family: ${(props) => props.theme.fonts.serif};
  font-size: 0.7rem;
}




`;
export default GlobalStyle;
