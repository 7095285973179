'use client';

import styled from 'styled-components';

const FacebookStyled = styled.a`
  width: ${(props) => props.width};
  &:hover {
    opacity: 0.8;
  }
`;

export const FacebookLogo = ({ href = '/', width = '20px' }) => (
  <FacebookStyled href={href} width={width} target="_blank" rel="noreferrer">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <circle cx="15" cy="15" r="15" />
      <path
        fill="#FFF"
        d="M16.4 23.9v-8.1h2.7l.4-3.2h-3.1v-2c0-.9.3-1.5 1.6-1.5h1.7V6.2c-.3 0-1.3-.1-2.4-.1-2.4 0-4.1 1.5-4.1 4.2v2.3h-2.7v3.2h2.7v8.1h3.2z"
      />
    </svg>
  </FacebookStyled>
);
