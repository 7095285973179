'use client';

import styled from 'styled-components';

const StyledLogoText = styled.h1`
  font-size: 1.45rem;
  color: #fff;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  padding: 12px 10px;
  background: #fff;
  color: var(--darkGrey);
  margin: 0;
  position: absolute;
  right: 0px;
  bottom: 81px;
`;

export const TTLogoText = () => <StyledLogoText>Tangible Tracks</StyledLogoText>;
