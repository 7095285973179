export const DefaultTheme = {
  colors: {
    // red: '#EB455F',
    red: '#FF6969',
    blue: '#BAD7E9',
    grey: '#e6e6e6',
    darkGrey: '#242424',
    black: '#000',
    white: '#fff',
    beige: '#FCFFE7',
    darkBlue: '#2B3467',
    yellow: '#ffa73c'
  },

  fonts: {
    serifDisplay: 'DM Serif Display',
    serif: 'Crimson Text, serif',
    sansSerif: 'Rubik, sans-serif'
  },

  padding: {
    default: '15px'
  },

  gutters: {
    default: '15px'
  },

  maxWidth: {
    default: '1500px'
  }
};
