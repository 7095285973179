'use client';

import styled from 'styled-components';

const StyledLogo = styled.div`
  ${(props) => props.styles};
  svg {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
  }
`;

export const TTLogo = ({ width = '50px', height = '50px', inverted = false, styles }) => (
  <StyledLogo width={width} height={height} $inverted={inverted} styles={styles}>
    <svg width="405" height="405" viewBox="0 0 405 405" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <circle fill={inverted ? '#000' : '#fff'} cx="202.5" cy="202.5" r="202.5" />
        <g fill={inverted ? '#fff' : '#000'} fillRule="nonzero">
          <path d="M111.162 192.05l-26.418 26.417 17.72 25.979-15.697 15.697-40.73-40.729L164.62 100.832l40.73 40.73-15.698 15.697-25.936-17.763-26.46 26.46L249.706 278.41l23.524-14.66 13.152 13.152-64.276 64.276-13.152-13.152 14.699-23.485z" />
          <path d="M161.98 141.225l-26.418 26.418 17.72 25.979-15.697 15.698-40.73-40.73L215.437 50.01l40.73 40.729-15.698 15.698-25.935-17.764-26.462 26.461 112.453 112.453 23.524-14.66 13.152 13.152-64.276 64.276-13.152-13.152 14.699-23.485z" />
        </g>
      </g>
    </svg>
  </StyledLogo>
);
