import { useEffect } from 'react';
import { usePiwikPro } from '@piwikpro/next-piwik-pro';

const usePiwik = () => {
  const { PageViews } = usePiwikPro();
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      PageViews.trackPageView();
    }
  }, [PageViews]);
};

export default usePiwik;
