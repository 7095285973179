'use client';

import { useRouter } from 'next/navigation';
import PropTypes from 'prop-types';
import axios from 'axios';
import Link from 'next/link';
import styled from 'styled-components';

const StyledNav = styled.nav`
  position: relative;
  text-align: right;
  z-index: 1000;
`;

const MenuUL = styled.ul`
  margin: 0;
`;

const MenuLI = styled.li`
  display: inline-block;
  position: relative;
  z-index: 100;
  padding: 0 0 7px 0;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;

  a {
    font-family: var(--font-rubik);
    font-weight: 500;
    color: ${(props) => props.$navColor};
    border-bottom: 5px solid ${(props) => props.$navColor};
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.5px;
    font-size: 0.9rem;
    line-height: 1.05rem;

    &:hover {
      border-bottom: 5px solid ${(props) => props.$hoverColor};
      color: ${(props) => props.$hoverColor};
      text-decoration: none;
    }
  }
`;

const Nav = ({ navColor = '#fff', hoverColor = 'var(--red)', userSession }) => {
  //console.log(userSession)

  const router = useRouter();
  const signOut = async () => {
    const res = await axios.post('/auth/logout');
    if (res.status === 200) router.push('/');
  };

  return (
    <StyledNav>
      <MenuUL className="text-right" role="menu">
        <MenuLI
          $hoverColor={hoverColor}
          $navColor={navColor}
          onKeyDown={() => console.log()}
          role="menuitem"
          tabIndex={0}
        >
          <Link href="/">Home</Link>
        </MenuLI>
        <MenuLI
          $hoverColor={hoverColor}
          $navColor={navColor}
          onKeyDown={() => console.log()}
          tabIndex={0}
          role="menuitem"
        >
          <Link href="/indie-unheard">Indie Unheard</Link>
        </MenuLI>
        <MenuLI
          $hoverColor={hoverColor}
          $navColor={navColor}
          onKeyDown={() => console.log()}
          tabIndex={0}
          role="menuitem"
        >
          <Link href="/find-a-recordstore">Find a record store</Link>
        </MenuLI>
        <MenuLI
          $hoverColor={hoverColor}
          $navColor={navColor}
          onKeyDown={() => console.log()}
          tabIndex={0}
          role="menuitem"
        >
          <Link href="/about">About</Link>
        </MenuLI>

        {/* {userSession && (
          <MenuLI
            $hoverColor={hoverColor}
            $navColor={navColor}
            onKeyDown={() => console.log()}
            tabIndex={0}
            role="menuitem"
          >
            <Link
              style={{ color: 'var(--yellow)', borderColor: 'var(--yellow)' }}
              href=""
              onClick={() => signOut()}
            >
              Logout
            </Link>
          </MenuLI>
        )}

        {!userSession && (
          <MenuLI
            $hoverColor={hoverColor}
            $navColor={navColor}
            onKeyDown={() => console.log()}
            tabIndex={0}
            role="menuitem"
          >
            <Link href="/signin">Sign In</Link>
          </MenuLI>
        )} */}
      </MenuUL>
    </StyledNav>
  );
};

Nav.propTypes = {
  navColor: PropTypes.string,
  hoverColor: PropTypes.string,
  userSession: PropTypes.bool
};

export default Nav;
